"use client";
import { makeAutoObservable } from "mobx";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { useRouter } from "next/navigation";
import i18n from '@/i18n/config';
import {
	createContext,
	PropsWithChildren,
	useContext,
	useMemo,
	useEffect, // Import useEffect
} from "react";
import { I18nextProvider } from "react-i18next";

export interface IUser{
	name?:string;
	picture?:string;
}
export class App {
	router: AppRouterInstance;
	appUser: IUser | undefined = undefined;
	productKeyword?:string = undefined;
	menus:any[]=[]

	constructor({
        router,
	}: {
		router: AppRouterInstance;
	}) {
        this.router=router;
		makeAutoObservable(this);
	}

	setAppUser = (appUser?: IUser) => {
		this.appUser = appUser;
	};

	setProductKeyword = (keyword?: string) => {
		this.productKeyword = keyword;
	};

	setMenus =(menus:any)=>{
		this.menus=menus;
	}

}

export const AppContext = createContext<{ app?: App }>({});
export const useApp = () => {
	const { app } = useContext(AppContext);
	return app as App;
};

export const AppProvider: React.FC<
	PropsWithChildren<{}>
> = ({ children}) => {
	const router:any = useRouter();
	const MApp = useMemo(() => {
		return new App({
			router,
		});
	}, []);

	// Add useEffect to track page views on route changes
	useEffect(() => {
		const handleRouteChange = (url: string) => {
			if (typeof window !== "undefined" && window.dataLayer) {
				window.dataLayer.push({
					event: "pageview",
					page: url,
				});
			}
		};

		// Track the initial page load
		handleRouteChange(router.asPath);

		// Subscribe to route changes
		router.events?.on("routeChangeComplete", handleRouteChange);
		return () => {
			router.events?.off("routeChangeComplete", handleRouteChange);
		};
	}, [router]);
	return (
        <I18nextProvider i18n={i18n}>
		    <AppContext.Provider value={{ app: MApp }}>{children}</AppContext.Provider>
        </I18nextProvider>
	);
};
