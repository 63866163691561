"use client";
import { GoogleAnalytics } from "@next/third-parties/google";
import { usePathname, useSearchParams } from "next/navigation";
import posthog from "posthog-js";
import { PostHogProvider, usePostHog } from "posthog-js/react";
import { PropsWithChildren, useEffect, useMemo } from "react";

const PostHogPageView = () => {
	const pathname = usePathname();
	const searchParams = useSearchParams();
	const posthog = usePostHog();
	useEffect(() => {
		// Track pageviews
		if (pathname && posthog) {
			let url = window.origin + pathname;
			if (searchParams.toString()) {
				url = url + `?${searchParams.toString()}`;
			}
			posthog.capture("$pageview", {
				$current_url: url,
			});
		}
	}, [pathname, searchParams, posthog]);

	return null;
};
export const AnalyticsProvider: React.FC<
	PropsWithChildren<{
		posthogApiKey: string;
		gaId:string;
	}>
> = ({ children, posthogApiKey,gaId }) => {
	const client = useMemo(
		() =>
			posthog.init(posthogApiKey, {
				api_host: "https://us.i.posthog.com",
				person_profiles: "identified_only",
				capture_pageview: false, // Disable automatic pageview capture, as we capture manually
				capture_pageleave: true, // Enable pageleave capture
			}),
		[posthogApiKey]
	);

	return (
		<PostHogProvider client={client}>
			{children}
			<GoogleAnalytics gaId={gaId} />
			<PostHogPageView />
		</PostHogProvider>
	);
};
