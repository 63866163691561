// axiosInstance.ts
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

const axiosInstance:AxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  timeout: 5000, 
});

const requestInterceptor = async (config: InternalAxiosRequestConfig) => {
  // const token = await getIdToken(); 
  const token = "";
  if (token) {
    config.headers.set("Authorization", `Bearer ${token}`);
  }

  if (config.data) {
    config.data = JSON.stringify(config.data);
    config.headers.set("Content-Type", "application/json");
  }
  return config;
};
const responseInterceptor = async (res: AxiosResponse) => {
  console.log("Response received:", res); 
  return res;
};

axiosInstance.interceptors.request.use(requestInterceptor, (error:any) => Promise.reject(error));
axiosInstance.interceptors.response.use(responseInterceptor, (error:any) => Promise.reject(error));

export default axiosInstance;