
import { IPageParam, IResPage } from './index.d';
import axiosInstance from './index';
export interface IProduct{
    ID: number
    CreatedAt: string
    UpdatedAt: string
    original_id:string
    ref_platform_id:number
    product_title: string
    product_url: string
    product_itopick_url: string
    product_price: number
    product_original_price: string
    currency: string
    country: string
    product_star_rating: number
    rating_distribution: any,
    product_num_ratings: number
    product_photo: string
    product_photos: string[]
    product_num_offers: number
    product_availability:string
    is_best_seller: boolean
    is_amazon_choice: boolean
    is_prime: boolean
    climate_pledge_friendly: boolean
    sales_volume: string
    about_product: any[]
    product_description: string
    product_information: {
      UPC: string
      ASIN: string
      Brand: string
      Color: string
      Capacity: string
      Batteries: string
      "Model Name": string
      "Filter Type": string
      "Form Factor": string
      "Item Weight": string
      "Battery Life": string
      Manufacturer: string
      "Power Source": string
      "Control Method": string
     "Controller Type": string
      "Special Feature": string
      "Number of pieces": number
      "Best Sellers Rank": string;
      "Country of Origin":string;
      "Item model number": string;
      "Batteries required": string;
      "Compatible Devices": string;
      "Product Dimensions": string;
     "Included Components": string;
      "Warranty Description": string;
      "Are Batteries Included": string;
      "Surface Recommendation": string;
      "Battery Cell Composition": string;
    };
    product_details: {
      Brand: string;
      Color: string
      "Model Name":string
      "Special Feature": any
      "Product Dimensions": string
    },
    customers_say: string
    review_aspects:any;
    category_path:  {
      id: string
      link: string
      name: string
    }[],
    product_variations: {
      size:  {
        asin: string
        value: string
        is_available: boolean
      }[]
    },
    ref_platform: {
      ID:number
      CreatedAt: string
      UpdatedAt: string
      name: string,
      icon: string
    },
    ref_categories:any;
    ref_review_summary: {
      ID: number
      CreatedAt: string
      UpdatedAt: string
      ref_product_id: string
      total_reviews: string
      total_ratings: string
      country: string
      domain: string
    },
    ref_itopick_review_sumary: {
      ID: number
      CreatedAt: string
      UpdatedAt: string
      ref_product_id: number
      total_reviews: number
      total_ratings: number
      country:string
    },
    ref_reviews:any
    ref_itopick_review:any
    similar_products?:IProduct[]
    reviews:any[]
    rank?:number
    price_history?:any[]
}
export interface IProductParams{
    keyword?:string;
    order?:string;
    original_id?:string;
    ref_platform_id?:number;
    ID?:number;
}

export async function apiGetPageProduct(params: IProductParams & IPageParam) {
    return axiosInstance.get<IResPage<IProduct>>('/api/i_product/getI_productList',{params}).then(r=>r.data)
}


export async function apiGetPageCategory(params: IProductParams & IPageParam) {
  return axiosInstance.get<IResPage<IProduct>>('/api/i_category/getI_categoryList',{params}).then(r=>r.data)
}


export async function apiGetPromotionProduct() {
  return axiosInstance.get<IResPage<IProduct>>('/api/i_product/getI_promotionList',{}).then(r=>r.data)
}

export async function apiGetProductDetail(params:IProductParams) {
  return axiosInstance.get<IProduct>('/api/i_product/findI_product',{params}).then(r=>r.data)
}

export async function apiGetProductDetailBackend(params:IProductParams) {
  return axiosInstance.get<IProduct>('https://www.itopick.com/api/i_product/findI_product',{params}).then(r=>r.data)
}




