"use client";
import { useApp } from "@/models/app";
import { useRouter } from "next/navigation";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import classNames from "classnames";
import SearchAutoComplete from "@/components/Search";
import { apiGetPageCategory } from "@/requests/product";
import { useRequest } from "ahooks";

const Menu = observer(() => {
  const router = useRouter();
  const MApp = useApp();
  const { data: categoryData } = useRequest(
    () => apiGetPageCategory({ page: 1, pageSize: 100 }),
    {
      onSuccess: (res: any) => {
        MApp.setMenus(res.data.list);
      },
    }
  );
  const goHome = () => {
    router.push("/");
  };
  const goUserCenter = () => {
    router.push("/user");
  };
  const goSign = () => {
    if (MApp.appUser?.name) {
      MApp.setAppUser(undefined);
    } else {
      router.push("/login");
    }
  };
  const searchProduct = (keyword: string) => {
    MApp.setProductKeyword(keyword);
  };
 
  const [history, setHistory] = useState<string[]>([]);
  useEffect(() => {
    const l = localStorage.getItem("searchHistory");
    const savedHistory = (l && JSON.parse(l)) || [];
    setHistory(savedHistory);
  }, []);

  useEffect(() => {
    localStorage.setItem("searchHistory", JSON.stringify(history));
  }, [history]);

  const enterSearch = (keyword:string) => {
    const set = new Set([keyword, ...history]);
    if (keyword) {
      setHistory([...Array.from(set)]);
    }
  };

  return (
    <div id="navContainer" className="nav">
      <p className="disclaimers">
        Itopick is community-supported.We may get paid by brands or deals,
        including promoted items.
      </p>

      <div className="nav-content">
        <a className="logo" onClick={goHome}></a>
        <SearchAutoComplete data={history} onSearch={enterSearch}/>
        <div className="nav-btn-list user-select-none">
          {/* <div id="dropAlertBtn" onClick={goUserCenter}>
            <div className="icon icon-dropAlert">
              <div className="badge">3</div>
            </div>
            <p>drop alert</p>
          </div>
          <div id="addADealBtn">
            <div className="icon icon-addADeal"></div>
            <p>add a deal</p>
          </div>
          <div id="toggleLanguageBtn">
            <div className="icon icon-Cn"></div>
            <p>中文版</p>
          </div>
          <div id="navSigninBtn" onClick={goSign}>
            <div className="icon icon-signin"></div>
            <p>{MApp.appUser?.name ?"signout":"signin"}</p>
          </div> */}
        </div>
      </div>
    </div>
  );
});
export default Menu;
