import(/* webpackMode: "eager", webpackExports: ["AnalyticsProvider"] */ "/app/app/analytics-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/index.scss");
;
import(/* webpackMode: "eager" */ "/app/app/good.scss");
;
import(/* webpackMode: "eager" */ "/app/app/icon.scss");
;
import(/* webpackMode: "eager" */ "/app/app/comments.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppProvider"] */ "/app/models/app.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.0.3_next@14.2.15_react@18.3.1/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.0.3_next@14.2.15_react@18.3.1/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.0.3_next@14.2.15_react@18.3.1/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1_sass@1.80.4/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1_sass@1.80.4/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/GeistVF.woff\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"geistSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1_sass@1.80.4/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/GeistMonoVF.woff\",\"variable\":\"--font-geist-mono\",\"weight\":\"100 900\"}],\"variableName\":\"geistMono\"}");
