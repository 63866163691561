import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translation_en from './en.json';

const resources = {
  english: {
    translation: translation_en,
  },
};

const options = {
  resources,
  lng: 'english',
  interpolation: {
    escapeValue: false,
  },
};

i18n
  .use(initReactI18next)
  .init(options);

export default i18n as any;
