import React, { useEffect, useState } from "react";
import { AutoComplete, Input } from "antd";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useApp } from "@/models/app";
import { useRouter } from "next/navigation";
import { observer } from "mobx-react";

const SearchAutoComplete:React.FC<{data:any[],onSearch?:(keyword:string)=>void}> = observer(({data,onSearch}) => {
  const MApp=useApp()
  const [options, setOptions] = useState(data?.map(i=>({value:i,label:<div className="item"><SearchOutlined/> {i}</div>})));
  const router = useRouter();
  useEffect(()=>{
    setOptions(data?.map(i=>({value:i,label:<div className="item"><SearchOutlined/>{i}</div>})))
  },[data])
  const handleSearch = (searchText:any) => {
    const filteredOptions = options.filter((option) =>
      option.value.toLowerCase().includes(searchText.toLowerCase())
    );
    if(!!filteredOptions?.length && searchText){
        setOptions(filteredOptions);
    }else{
      setOptions(data?.map(i=>({value:i,label:<div className="item"><SearchOutlined/>{i}</div>})))
    }
  };
  const handleEnter=(e:any)=>{
    if(e.code=="Enter"){
      onSearch?.(MApp.productKeyword || '')
      router.push("/")
    }
  }

  const handleSelect = (value:any) => {
    router.push("/")
  };
  return (
    <AutoComplete
      style={{ width: "100%" }}
      className="search-container"
      options={options}
      value={MApp.productKeyword}
      onChange={MApp.setProductKeyword}
      onSearch={handleSearch}
      onSelect={handleSelect}
    >
     <Input.Search placeholder="AirPods" size="large" allowClear  enterButton onKeyDown={handleEnter}  className="input-search"/>
    </AutoComplete>
  );
});

export default SearchAutoComplete;